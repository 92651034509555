// google.maps.event.trigger(map, 'resize');]



$( document ).ready(function() {

  function resizeMaps() {
    if($('#panel1:visible').length){
      google.maps.event.trigger(map_1, 'resize');
      map_1.setCenter(marker_1.getPosition());
    }
    if($('#panel2:visible').length){
      google.maps.event.trigger(map_2, 'resize');
      map_2.setCenter(marker_2.getPosition());
    }
    if($('#panel3:visible').length){
      google.maps.event.trigger(map_3, 'resize');
      map_3.setCenter(marker_3.getPosition());
    }

    if($('#panel5:visible').length){
      google.maps.event.trigger(map_1, 'resize');
      map_1.setCenter(marker_1.getPosition());
    }
  }

  if ( $( "#display-location-map-1" ).length ) {

    $( window ).resize(function() {
      resizeMaps();
    });

    $('#contact--institution-tabs').on('change.zf.tabs', function() {
      resizeMaps();
    });

    $('#partner--tabs').on('change.zf.tabs', function() {
      resizeMaps();
    });

    var map_1 = new google.maps.Map(document.getElementById('display-location-map-1'), {
      zoom: 15,
      center: uluru_1,
      scrollwheel: false,
      navigationControl: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: true,
      disableDefaultUI: true,
    });



    var marker_1 = new google.maps.Marker({
      position: uluru_1,
      map: map_1,
      title: '',
      animation: google.maps.Animation.DROP,
      icon: {
        path: 'M40,39.5c4.7-4.9,7.6-11.2,7.6-15.8C47.6,10.6,37,0,23.9,0C10.8,0,0.2,10.6,0.2,23.6c0,4.8,2.8,10.9,7.3,15.7L23.8,66L40,39.5z M23.9,14.2c4.2,0,7.6,3.4,7.6,7.6c0,4.2-3.4,7.6-7.6,7.6s-7.6-3.4-7.6-7.6C16.3,17.6,19.7,14.2,23.9,14.2z',
        fillColor: '#BAD794',
        fillOpacity: 1,
        strokeColor: '',
        strokeWeight: 0,
        scale: 1/2,
        origin: new google.maps.Point(0,0),
        anchor: new google.maps.Point(21,42),
      },
    });
  }

  if ( $( "#display-location-map-2" ).length ) {

    $( window ).resize(function() {
      resizeMaps();
    });

    $('#contact--institution-tabs').on('change.zf.tabs', function() {
      resizeMaps();
    });

    $('#partner--tabs').on('change.zf.tabs', function() {
      resizeMaps();
    });


    var map_2 = new google.maps.Map(document.getElementById('display-location-map-2'), {
      zoom: 15,
      center: uluru_2,
      scrollwheel: false,
      navigationControl: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: true,
      disableDefaultUI: true,
    });



    var marker_2 = new google.maps.Marker({
      position: uluru_2,
      map: map_2,
      title: '',
      animation: google.maps.Animation.DROP,
      icon: {
        path: 'M40,39.5c4.7-4.9,7.6-11.2,7.6-15.8C47.6,10.6,37,0,23.9,0C10.8,0,0.2,10.6,0.2,23.6c0,4.8,2.8,10.9,7.3,15.7L23.8,66L40,39.5z M23.9,14.2c4.2,0,7.6,3.4,7.6,7.6c0,4.2-3.4,7.6-7.6,7.6s-7.6-3.4-7.6-7.6C16.3,17.6,19.7,14.2,23.9,14.2z',
        fillColor: '#68BBDB',
        fillOpacity: 1,
        strokeColor: '',
        strokeWeight: 0,
        scale: 1/2,
        origin: new google.maps.Point(0,0),
        anchor: new google.maps.Point(21,42),
      },
    });
  }

  if ( $( "#display-location-map-3" ).length ) {

    $( window ).resize(function() {
      resizeMaps();
    });

    $('#contact--institution-tabs').on('change.zf.tabs', function() {
      resizeMaps();
    });

    $('#partner--tabs').on('change.zf.tabs', function() {
      resizeMaps();
    });

    var map_3 = new google.maps.Map(document.getElementById('display-location-map-3'), {
      zoom: 15,
      center: uluru_3,
      scrollwheel: false,
      navigationControl: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: true,
      disableDefaultUI: true,
    });

    var marker_3 = new google.maps.Marker({
      position: uluru_3,
      map: map_3,
      title: '',
      animation: google.maps.Animation.DROP,
      icon: {
        path: 'M40,39.5c4.7-4.9,7.6-11.2,7.6-15.8C47.6,10.6,37,0,23.9,0C10.8,0,0.2,10.6,0.2,23.6c0,4.8,2.8,10.9,7.3,15.7L23.8,66L40,39.5z M23.9,14.2c4.2,0,7.6,3.4,7.6,7.6c0,4.2-3.4,7.6-7.6,7.6s-7.6-3.4-7.6-7.6C16.3,17.6,19.7,14.2,23.9,14.2z',
        fillColor: '#DC2934',
        fillOpacity: 1,
        strokeColor: '',
        strokeWeight: 0,
        scale: 1/2,
        origin: new google.maps.Point(0,0),
        anchor: new google.maps.Point(21,42),
      },
    });
  }

  //
  //
  //

  $('#about--institution-tabs').on('change.zf.tabs', function() {
    if((Foundation.MediaQuery.current == 'small') || (Foundation.MediaQuery.current == 'medium')) {
      var scroll = new SmoothScroll();
      var anchor = document.querySelector( '#about--tab-content' );
      scroll.animateScroll( anchor );
    }
  })

  $('#contact--institution-tabs').on('change.zf.tabs', function() {
    if((Foundation.MediaQuery.current == 'small') || (Foundation.MediaQuery.current == 'medium')) {
      var scroll = new SmoothScroll();
      var anchor = document.querySelector( '#contact--tab-content' );
      scroll.animateScroll( anchor );
    }
  })

  //
  //
  //

  $('#subscriber-button').click(function() {
    event.preventDefault();
    console.log($("#newsletterform").validate());
    var valid = 'valid';
    if ($("#subscriber-email").valid() == false) {
      valid = 'invalid';
    }
    console.log($("#subscriber-email").valid());
    console.log(valid);

    if (valid == 'valid') {
      var data = {
        'action': 'create_subscription',
        'subscriber_email': $("#subscriber-email").val(),
      };
      jQuery.ajax({
        url: ajax_url,
        data: data,
        type: 'post',
        success: function(response) {
          $("#subscriber-response").html(response);
          $("#subscriber-email").val("");
        }
      });
    }
  })
});
